import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VueCookieNext } from 'vue-cookie-next'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import fastclick from 'fastclick'
import VueLazyload from 'vue-lazyload'

fastclick.attach(document.body)
const loadimage = require('./assets/MAdvisor.png')
const errorimage = require('./assets/MAdvisor.png')

const app = createApp(App);
     app.use(router)
     router.app = app
     app.use(VueAxios, axios)
     app.use(VueCookieNext)
     app.use(Toast)
     app.use(VueLoading, {
          // props
          color: '#236EC5',
          loader:'dots'
      })
      app.use(VueLazyload, {
          preLoad: 1.3,
          error: errorimage,
          loading: loadimage,
          attempt: 1
      })
     app.mount('#app')
